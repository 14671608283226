class BooyaProviders {
    names = {
        GOOGLE: 'google',
        FACEBOOK: 'facebook',
        LINKEDIN: 'linkedin',
        TWITTER: 'twitter',
        EMAIL: 'email',
    };

    map = {
        [this.names.GOOGLE]: { name: 'Google', logo: 'google.svg' },
        [this.names.FACEBOOK]: { name: 'Facebook', logo: 'facebook.svg' },
        [this.names.LINKEDIN]: { name: 'LinkedIn', logo: 'linkedin.svg' },
        [this.names.TWITTER]: { name: 'Twitter', logo: 'twitter.svg' },
        [this.names.EMAIL]: { name: 'Email', logo: 'envelope.svg' },
    };

    getList() {
        return Object.keys(this.map);
    }

    getName(provider) {
        const { name } = this.map[provider] || {};
        return name;
    }

    getLogo(provider) {
        const { logo } = this.map[provider] || {};
        return `https://cdn.jsdelivr.net/npm/@inboundlabs/booya-ui@0.1/dist/images/${logo}`;
    }
}

class BooyaWidgets {

    providers = new BooyaProviders();

    selectors = {
        modal: '__booya-modal-overlay',
        authWidgetsModal: '__booya-modal-overlay-auth-widgets',
        authWidgetsWrapper: '__booya-auth-widgets-wrapper',
        authWidgetSection: '__booya-auth-widget-section',
        authWidgetSectionSwitch: '__booya-auth-widget-section-switch',
    };

    banners = {
        INFO: 'info',
        SUCCESS: 'success',
        WARN: 'warn',
        DANGER: 'danger',
    }

    /* Form Widgets */
    renderFormField(input, name, settings) {
        const {
            type: input_type, value, required, placeholder, options, selected,
            label, help_text, character_limit, word_limit
        } = settings;

        if(input === 'input' && input_type === 'checkbox') {
            return `
<fieldset ${required ? `required` : ''} class="checkbox">
  <input type="checkbox" name="${name}" value="${value}" data-label="${label || ''}">
  <span></span>
  <label>${label || ''} ${required ? '*' : ''}</label>
</fieldset>
                `
        }

        const styleFixer = 'style="width: 100% !important;"';

        let inputHtml = '';
        switch (input) {
            case 'textarea':
                inputHtml = `
<textarea name="${name}"
                  ${required ? `required` : ''}
                  ${character_limit ? `data-character-limit="${character_limit}"` : ''}
                  ${word_limit ? `data-word-limit="${word_limit}"` : ''}
                  ${placeholder ? `placeholder="${placeholder}${(required && !label) && ' *' || ''}"` : ''}
                  ${styleFixer}
                  >${value || ''}</textarea>
            `;
                break;
            case 'input':
            case 'hidden':
                inputHtml = `
<input type="${input_type || 'text'}"
               name="${name}"
               ${required ? `required` : ''}
               ${character_limit ? `data-character-limit="${character_limit}"` : ''}
               ${word_limit ? `data-word-limit="${word_limit}"` : ''}
               ${value ? `value="${value}"` : ''}
               ${placeholder ? `placeholder="${placeholder}${(required && !label) && ' *' || ''}"` : ''}
               ${styleFixer}
        >
            `;
                break;
            case 'select':
            case 'multi-select':
                inputHtml = `
<select name="${name}"
        ${input === "multi-select" ? `id="${name}-select"` : ''}
        ${styleFixer}
        >
   <option value="">${placeholder || ''}</option> 
    ${options ? (
        options.map(option => {
                        return `
    <option value="${option.id}"
        ${selected === option.id ? `selected` : ''}
        ${input_type === "multi-select" ? `id="${option.name}-${option.id}"` : ''}>
        ${option.name}
    </option>
        `;
                    })) : ''}
</select>
            `;
                break;
            default:
                inputHtml = input;
                break;
        }

        const isHidden = input_type === 'hidden';

        if(isHidden) {
            return inputHtml;
        }

        return `
<fieldset ${required ? `required` : ''}>
    ${label? `
    <label>${label} ${required ? '*' : `
        <span class="optional">(optional)</span>
    `}</label>
    ` : ''}
    
    ${help_text ? `
    <label>
        <legend>${help_text}</legend>
    </label>
    ` : ''}
    
    <div>
        ${inputHtml}
    </div>
</fieldset>
    `;
    }

    renderDivider(label='') {
        return `
<div class="__booya-section-separator">
    <div class="__booya-section-separator-line"></div>
    ${label?`
    <div class="__booya-section-separator-text">${label}</div>
    <div class="__booya-section-separator-line"></div>
    `:''}
</div>
        `;
    }

    renderOAuthButton(provider, options) {
        const { label, redirect_url, error_redirect_url } = options || {},
            name = this.providers.getName(provider),
            logo = this.providers.getLogo(provider);

        const nextUrl = (booya && booya.getUrlParameter('next')) || (window.booyaConfig && window.booyaConfig.memberUrl) || location.href || '';
        const guestUrl = (window.booyaConfig && window.booyaConfig.guestUrl) || location.href || '';

        const url = `${(window.booyaConfig && window.booyaConfig.apiRoot) || 'https://auth.booya.io'}/auth/signin/${provider}/?grant_type=code&redirect_uri=${ redirect_url || nextUrl || '' }&redirect_uri_error=${ error_redirect_url || location.href || guestUrl || '' }`;
        return `
<a href="${provider === this.providers.names.EMAIL?'#':url}" 
   class="__booya-btn __booya-btn-outline __booya-btn-signin __booya-btn-signin-${ provider } ${provider === this.providers.names.EMAIL?'__booya-signin-email-open':''}">
    <img src="${logo}" alt="${ name }"/>${ label || `Sign in with ${name}` } 
</a>
        `;
    }

    /* Authentication */
    renderAuthFormValidation(error, success, warning) {
        return `
<div class="form-success" style="display: none">
    ${success || ''}
</div>

<div class="form-error" style="display: none" data-error="${error || ''}">
    ${error || ''}
</div>

<div class="form-validation-error" style="display: none">
    ${warning || '❌ Please fix the errors in the form and try again!'}
</div>
    `;
    }

    renderAuthForm(type, title, fields, action, options) {
        const {error, success, warning, header, footer, hideAction, repeatValidation = false, target} = options || {};
        const validation = this.renderAuthFormValidation(error, success, warning);

        const markup = `
<form name="${type}" class="__booya-form __booya-${type}-form">
    ${title || ''}
    
    ${header || ''}
    
    ${validation || ''}
    
    ${fields || ''}
    
    ${footer || ''}

    ${repeatValidation && validation || ''}
    
    ${hideAction ? '' : `
    <div class="__booya-form-action">
        <button type="submit" class="__booya-btn">
            ${action}
        </button>
    </div>
    `}
</form>
    `;
        if(target) {
            $(target).html(markup);
            if(window.booya) {
                window.booya.initUI();
            }
            return ;
        }
        return markup;
    }

    /* Verify */
    renderVerifyForm(title, fields, action, options) {
        const {error, success, header, footer, fieldOverrides = {}, ...rest} = options || {};
        return this.renderAuthForm(
            'verify',
            title || '<h3>Sign in or Register</h3>',
            fields || `
        ${this.renderFormField('input', 'email', {
                type: 'email',
                label: 'Email',
                placeholder: 'Email',
                required: true,
                ...(fieldOverrides.email || {}),
            })}
        `,
            action || 'Next',
            {
                error: error || "❌ Oops, something went wrong, Please try again.",
                success: success || "🎉 Email verified successfully!",
                header,
                footer,
                ...rest
            }
        );
    }

    /* Sign In */
    renderSignInForm(title, fields, action, options) {
        const {error, success, header, footer, fieldOverrides = {}, ...rest} = options || {};
        return this.renderAuthForm(
            'signin',
            title || '<h3>Sign In</h3>',
            fields || `
        ${this.renderFormField('input', 'email', {
                type: 'email',
                label: 'Email',
                placeholder: 'Email',
                required: true,
                ...(fieldOverrides.email || {}),
            })}
        ${this.renderFormField('input', 'password', {
                type: 'password',
                label: 'Password',
                placeholder: 'Password',
                required: true,
                ...(fieldOverrides.password || {}),
            })}
        `,
            action || 'Sign In',
            {
                error: error || "❌ Oops, we couldn't find an account with that email and password, Please try again.",
                success: success || "🎉 Signed in successfully!",
                header,
                footer,
                ...rest
            }
        );
    }

    /* Sign Up */
    renderSignUpForm(title, fields, action, options) {
        const {error, success, header, footer, includeTel = false, includeLabels = true, repeatValidation = false, fieldOverrides = {}, ...rest} = options || {};
        return this.renderAuthForm(
            'signup',
            title || '<h3>Register</h3>',
            fields || `
        ${this.renderFormField('input', 'email', {
                type: 'email',
                label: includeLabels && 'Email' || '',
                placeholder: 'Email',
                required: true,
                ...(fieldOverrides.email || {}),
            }, 'email')}
        ${this.renderFormField('input', 'password', {
                type: 'password',
                label: includeLabels && 'Password' || '',
                placeholder: 'Password',
                required: true,
                ...(fieldOverrides.password || {}),
            })}
        ${this.renderFormField('input', 'first_name', {
                type: 'text',
                label: includeLabels && 'First Name' || '',
                placeholder: 'First Name',
                required: true,
                ...(fieldOverrides.first_name || {}),
            })}
        ${this.renderFormField('input', 'last_name', {
                type: 'text',
                label: includeLabels && 'Last Name' || '',
                placeholder: 'Last Name',
                required: true,
                ...(fieldOverrides.last_name || {}),
            })}
        ${includeTel ? this.renderFormField('input', 'telephone', {
                type: 'tel',
                label: includeLabels && 'Telephone' || '',
                placeholder: 'Telephone',
                required: false,
                ...(fieldOverrides.telephone || {}),
            }): ''}
        `,
            action || 'Register',
            {
                error: error || "❌ Sorry, we can't sign you up with email and password at this time.",
                success: success || "🎉 Please check your email inbox for instructions.",
                header,
                footer,
                repeatValidation,
                ...rest
            }
        );
    }

    /* Complete Profile */
    renderProfileForm(title, fields, action, options) {
        const {error, success, header, footer, includeTel = true, repeatValidation = false, fieldOverrides = {}, ...rest} = options || {};
        const user = window.user || {};
        return this.renderAuthForm(
            'profile',
            title || '<h3>Complete Profile</h3>',
            fields || `
        ${this.renderFormField('input', 'first_name', {
                type: 'text',
                label: 'First Name',
                placeholder: 'First Name',
                required: true,
                value: user.first_name || '',
                ...(fieldOverrides.first_name || {}),
            })}
        ${this.renderFormField('input', 'last_name', {
                type: 'text',
                label: 'Last Name',
                placeholder: 'Last Name',
                required: true,
                value: user.last_name || '',
                ...(fieldOverrides.last_name || {}),
            })}
        ${includeTel ? this.renderFormField('input', 'telephone', {
                type: 'tel',
                label: 'Telephone',
                placeholder: 'Telephone',
                required: false,
                value: user.telephone || '',
            }) : ''}
        `,
            action || 'Save',
            {
                error: error || "❌ Oops, we couldn't save your profile, Please try again.",
                success: success || "🎉 Profile updated successfully!",
                header,
                footer,
                repeatValidation,
                ...rest
            }
        );
    }

    /* Recover Account */
    renderRecoverForm(title, fields, action, options) {
        const {error, success, header, footer, fieldOverrides = {}, ...rest} = options || {};
        return this.renderAuthForm(
            'recover-account',
            title || '<h3>Account Recovery</h3>',
            fields || `
        ${this.renderFormField('input', 'email', {
                type: 'email',
                label: 'Email',
                placeholder: 'Email',
                required: true,
                ...(fieldOverrides.email || {}),
            })}
        `,
            action || 'Recover',
            {
                error: error || "❌ Oops, we couldn't find an account with that email, Please try again.",
                success: success || "🎉 Please check your email inbox for instructions.",
                header,
                footer,
                ...rest
            }
        );
    }

    /* Reset Password */
    renderResetForm(title, fields, action, options) {
        const {error, success, header, footer, fieldOverrides = {}, ...rest} = options || {};
        return this.renderAuthForm(
            'reset-password',
            title || '<h3>Reset Password</h3>',
            fields || `
        ${this.renderFormField('input', 'new_password', {
                type: 'password',
                label: 'New Password',
                placeholder: 'New Password',
                required: true,
                ...(fieldOverrides.new_password || {}),
            })}
        ${this.renderFormField('input', 'confirm_password', {
                type: 'password',
                label: 'Confirm Password',
                placeholder: 'Confirm Password',
                required: true,
                ...(fieldOverrides.confirm_password || {}),
            })}
        `,
            action || 'Save',
            {
                error: error || "❌ Oops, we couldn't change your password, Please try again.",
                success: success || "🎉 Password updated successfully!",
                header,
                footer,
                ...rest
            }
        );
    }

    /* Modals */
    renderModal(title, content, options) {
        const { className, isOpen=false, target } = options || {};
        const markup = `
<div class="${this.selectors.modal} ${className || ''}" style="display: ${isOpen?'block':'none'}">
  <div class="__booya-modal">
    <div class="__booya-modal-header">
      ${title || ''}

      <div class="__booya-close">
        <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5,1.5l-13,13m0-13,13,13" transform="translate(-1 -1)"></path>
        </svg>
      </div>
    </div>
    <div class="__booya-modal-content">
        ${content || ''}
    </div>
  </div>
</div>
    `;
        if(target || isOpen) {
            if(target) {
                $(target).html(markup);
            } else {
                $('body').append(markup);
            }
            if(window.booya) {
                window.booya.initUI();
            }
            return ;
        }
        return markup;
    }

    closeModal() {
        $(`.${this.selectors.modal}`).hide();
    }

    removeModal() {
        $(`.${this.selectors.modal}`).hide();
    }

    /* Complete Auth Widgets */
    renderAuthWidgets(options) {
        const { signIn, signUp, recover, header, footer, switches, target, className, modal=null } = options || {};
        const { form :signInForm, args: signInFormArguments, oauth :oauthSection, footer :signInFooter } = signIn || {};
        const { form :signUpForm, args: signUpFormArguments, footer :signUpFooter } = signUp || {};
        const { form :recoverForm, args: recoverFormArguments, footer :recoverFooter } = recover || {};
        const { signIn :signInSwitch, signUp :signUpSwitch, recover :recoverSwitch } = switches || {};
        const { info :signInSwitchInfo, cta: signInSwitchCTA } = signInSwitch || {};
        const { info :signUpSwitchInfo, cta: signUpSwitchCTA } = signUpSwitch || {};
        const { info :recoverSwitchInfo, cta: recoverSwitchCTA } = recoverSwitch || {};


        let oauthProviders = [];
        if(window.booyaConfig && Array.isArray(window.booyaConfig.providers)) {
            oauthProviders = window.booyaConfig.providers.filter(provider => provider !== this.providers.names.EMAIL);
        }

        let markup = `
<div class="${this.selectors.authWidgetsWrapper}${className?` ${className}`:''}">
    ${header || ''}
    <div class="${this.selectors.authWidgetSection} ${this.selectors.authWidgetSection}-signin">
        ${signInForm || this.renderSignInForm(...(signInFormArguments || []))}
        ${oauthSection || (
            oauthProviders.length && `
                ${this.renderDivider('OR')}
                ${oauthProviders.map(provider => this.renderOAuthButton(provider)).join('')}
        ` || '')}
        ${signInFooter || `
            <div class="${this.selectors.authWidgetSectionSwitch}-wrapper ${this.selectors.authWidgetSectionSwitch}-wrapper-recover">
            ${recoverSwitchInfo || ''}<a href="#" class="__booya-link ${this.selectors.authWidgetSectionSwitch}-recover">${recoverSwitchCTA || 'Forgot password?'}</a>
            </div>
            
            ${this.renderDivider()}
            
            <div class="${this.selectors.authWidgetSectionSwitch}-wrapper ${this.selectors.authWidgetSectionSwitch}-wrapper-signup">
            ${signUpSwitchInfo || "Don't have an account?"} <a href="#" class="__booya-link ${this.selectors.authWidgetSectionSwitch}-signup">${signUpSwitchCTA || 'Sign up'}</a>
            </div>
        `}
    </div>
    <div class="${this.selectors.authWidgetSection} ${this.selectors.authWidgetSection}-signup" style="display: none">
        ${signUpForm || this.renderSignUpForm(...(signUpFormArguments || []))}
        ${signUpFooter || `
            <div class="${this.selectors.authWidgetSectionSwitch}-wrapper ${this.selectors.authWidgetSectionSwitch}-wrapper-signin">
            ${signInSwitchInfo || ''} <a href="#" class="__booya-link ${this.selectors.authWidgetSectionSwitch}-signin">${signInSwitchCTA || 'Back to Sign In'}</a>
            </div>
        `}
    </div>
    <div class="${this.selectors.authWidgetSection} ${this.selectors.authWidgetSection}-recover" style="display: none">
        ${recoverForm || this.renderRecoverForm(...(recoverFormArguments || []))}
        ${recoverFooter || `
            <div class="${this.selectors.authWidgetSectionSwitch}-wrapper ${this.selectors.authWidgetSectionSwitch}-wrapper-signin">
            ${signInSwitchInfo || ''} <a href="#" class="__booya-link ${this.selectors.authWidgetSectionSwitch}-signin">${signInSwitchCTA || 'Back to Sign In'}</a>
            </div>
        `} 
    </div>
    ${footer || ''}
</div>
        `;

        if(modal) {
            const { title, className :modalClassName, isOpen=false, ...otherOptions } = modal || {};
            markup = this.renderModal(
                title, markup, {
                    ...otherOptions,
                    className: `${this.selectors.authWidgetsModal}${modalClassName?` ${modalClassName}`:''}`,
                    isOpen
                });
        }

        if(target) {
            $(target).html(markup);
            if(window.booya) {
                window.booya.initUI();
            }
            return ;
        }
        return markup;
    }

    openAuthWidget(widget) {
        $(`.${this.selectors.authWidgetSection}`).hide();
        $(`.${this.selectors.authWidgetSection}-${widget}`).show();
    }

    renderUserWidget(target, options) {
        const user = window.user,
            self = this;
        if(!user || !target || $(target).find('#__booya-user-widget').length) return null;

        const markup =  `
<div id="__booya-user-widget">
    <div>
        <img src="${user.avatar}"/> <span class="__booya-user-name">${`${user.first_name} ${user.last_name}`.trim() || user.email.replace(/@.*/i, '')}</span>
    </div>
    <div class="__booya-user-widget-options">
        <div class="__booya-user-widget-option __booya-edit-profile">Edit Profile</div>
        <div class="__booya-user-widget-option __booya-logout">Sign Out</div>
    </div>
</div>
        `;
        $(target).append(markup);
        const userWidget = $('#__booya-user-widget');

        const { profile :onEditProfile, logout :logOut } = options || {};

        userWidget.find('.__booya-edit-profile').off('click').click(function () {
            // Edit Profile
            if(onEditProfile) {
                onEditProfile();
            } else {
                booya.trigger(booya.events.EDIT_PROFILE_PROMPT);

                if(!booya.isEventOverridden(booya.events.EDIT_PROFILE_PROMPT)) {
                    self.renderModal(
                        '',
                        self.renderProfileForm('<h3>Edit Profile</h3>'),
                        {
                            isOpen: true,
                        },
                    )
                }
            }
        });

        userWidget.find('.__booya-logout').off('click').click(function () {
            // Logout
            if(logOut) {
                logOut();
            } else {
                booya.logOut();
            }
        });
    }

    renderBanner(content, appearance='info', isOpen=true) {
        const markup = `
<div class="__booya-banner${appearance?` __booya-banner-${appearance|| 'info'}`:''}">
    <div class="__booya-banner-content">
        <div class="__booya-banner-message">${content}</div>
        <div class="__booya-banner-close">
            <img src="https://cdn.jsdelivr.net/npm/@inboundlabs/booya-ui@0.1/dist/images/close-white.svg" width="16px" />
        </div>
    </div>
</div>
        `;
        if(isOpen) {
            $('body').append(markup);
            if(window.booya) {
                window.booya.initUI();
            }
        }
        return markup;
    }

    renderAccountVerifiedBanner(content) {
        return this.renderBanner(
            content || '🎉 Account verification complete.', this.banners.SUCCESS, true
        );
    }
}

class Booya {

    constructor() {
        this.isInitialized = false;
        this.isReady = false;

        this.setConfig({
            portalID: '',
            appID: '',
            memberUrl: '',
            guestUrl: '',
            recoverUrl: '',
            resetUrl: '',
            isTest: /booya-test=true/ig.test(window.location.search),
            mode: 'live',
            ...(window.booyaConfig || {})
        });

        const self = this;
        if(self.config.appID || self.config.portalID) {
            $.ajax({
                url: self.config.appID?self.getAPIEndpointURL('/config'):`${self.getAPIBaseURL()}/config/${self.config.portalID}`,
                type: "GET",
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                }
            }).done(function (data) {
                self.setConfig(data);

                completeInitialization();
            }).fail(function (error) {
                if (error && error.responseText) {
                    let data = $.parseJSON(error.responseText);
                    if (data && data.reason === 'not_setup') {
                        self.widgets.renderBanner(
                            'This website is not yet set up, Visit <a href="https://go.booya.io" target="_blank">go.booya.io</a> to complete the setup',
                            booya.widgets.banners.WARN,
                        );
                    }
                }

                completeInitialization();
            });
        }

        function completeInitialization() {
            $(document).ready(function () {
                if(self.config.portalID && self.config.appID) {
                    // We have enough info to initialize Booya
                    self.init();

                    self.trigger(self.events.READY);

                    this.isReady = true;
                }
            });
        }
    }

    config = window.booyaConfig || {};

    state = {
        events: [],
    };

    widgets = new BooyaWidgets();

    events = {
        // Ready
        READY: 'booya-ready',
        // Register
        REGISTER_SUCCESS: 'booya-register-success',
        REGISTER_FAILED: 'booya-register-failed',
        // Login
        LOGIN_SUCCESS: 'booya-login-success',
        OAUTH_LOGIN_SUCCESS: 'booya-oauth-login-success',
        LOGIN_FAILED: 'booya-login-failed',
        // Identify
        IDENTIFY_SUCCESS: 'booya-identify-success',
        IDENTIFY_FAILED: 'booya-identify-failed',
        // Logout
        LOGOUT_SUCCESS: 'booya-logout-success',
        LOGOUT_FAILED: 'booya-logout-failed',
        // Verify Account
        VERIFY_ACCOUNT_SUCCESS: 'booya-verify-account-success',
        VERIFY_ACCOUNT_FAILED: 'booya-verify-account-success',
        // Validate account
        ACCOUNT_EXISTS_SUCCESS: 'booya-account-exists-success',
        ACCOUNT_EXISTS_FAILED: 'booya-account-exists-failed',
        // Update Profile
        PROFILE_UPDATE_SUCCESS: 'booya-profile-update-success',
        PROFILE_UPDATE_FAILED: 'booya-profile-update-failed',
        // COMPLETE PROFILE PROMPT
        COMPLETE_PROFILE_PROMPT: 'booya-complete-profile-prompt',
        EDIT_PROFILE_PROMPT: 'booya-edit-profile-prompt',
        // RECOVER
        RECOVER_SUCCESS: 'booya-recover-success',
        RECOVER_FAILED: 'booya-recover-failed',
        // RESET
        RESET_SUCCESS: 'booya-reset-success',
        RESET_FAILED: 'booya-reset-failed',

        // Keep for legacy integrations
        VERIFY_EMAIL_SUCCESS: 'booya-account-exists-success', // Formerly: booya-verify-email-success
        VERIFY_EMAIL_FAILED: 'booya-account-exists-failed', // Formerly: booya-verify-email-failed
    };

    providers = new BooyaProviders();

    setConfig(config) {
        let newConfig = {
            ...(window.booyaConfig || {}),
            ...(this.config || {}),
            ...(config || {}),
        };

        // Set API Root
        newConfig.apiRoot = this.getAPIEndpointURL('');

        this.config = newConfig;
        window.booyaConfig = newConfig;
    }

    setCookie(cookieName, cookieValue, expireDays) {
        let d = new Date();
        d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    getCookie(cookieName) {
        let name = cookieName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    getAPIBaseURL() {
        return (this.config.isTest && this.config.mode !== 'staging' ? 'http://localhost:5000' : 'https://auth.booya.io');
    }

    getAPIEndpointURL(path) {
        return this.getAPIBaseURL() + (this.config.appID ? `/${this.config.appID}` : '') + (path || '');
    }

    trigger(eventName, data) {
        $.event.trigger({
            type: eventName,
            ...(data || {}),
            time: new Date()
        });
    }

    isEventOverridden(eventName) {
        return (this.state.events || []).includes(eventName);
    }

    validateForm(form) {
        $('.error').remove();

        let emptyMessage = "Please complete this required field.",
            invalidURLMessage = "Please enter a valid URL",
            invalidVideoURLMessage = "Please enter a Youtube, Vimeo, Wistia or Vidyard URL.",
            invalidHubSpotPartnerURLMessage = "Please enter a HubSpot Partner Directory profile URL.",
            invalidEmailMessage = "Please enter a valid email.",
            fileEmpty = "Please select a file.",
            productsUsedEmpty = "Please select at least one product.";

        function isUrlValid(url) {
            return /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(url);
        }

        function isEmailValid(email) {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email);
        }

        function isVideoUrlValid(url) {
            return /https?:\/\/(.*youtube\.com\/watch.*|.*\.youtube\.com\/v\/.*|youtu\.be\/.*|.*\.youtube\.com\/user\/.*|.*\.youtube\.com\/.*#.*\/.*|m\.youtube\.com\/watch.*|m\.youtube\.com\/index.*|.*\.youtube\.com\/profile.*|.*\.youtube\.com\/view_play_list.*|.*\.youtube\.com\/playlist.*|www\.youtube\.com\/embed\/.*|youtube\.com\/gif.*|www\.youtube\.com\/gif.*|www\.youtube\.com\/attribution_link.*|youtube\.com\/attribution_link.*|youtube\.ca\/.*|youtube\.jp\/.*|youtube\.com\.br\/.*|youtube\.co\.uk\/.*|youtube\.nl\/.*|youtube\.pl\/.*|youtube\.es\/.*|youtube\.ie\/.*|it\.youtube\.com\/.*|youtube\.fr\/.*|www\.vimeo\.com\/groups\/.*\/videos\/.*|www\.vimeo\.com\/.*|vimeo\.com\/groups\/.*\/videos\/.*|vimeo\.com\/.*|vimeo\.com\/m\/#\/.*|player\.vimeo\.com\/.*|app\.wistia\.com\/embed\/medias\/.*|wistia\.com\/.*|.*\.wistia\.com\/.*|.*\.wi\.st\/.*|wi\.st\/.*|.*vidyard\.com\/.*)/i.test(url);
        }

        function isHubSpotPartnerUrlValid(url) {
            return /https?:\/\/www\.hubspot\.com\/agencies\/.+/i.test(url);
        }

        // Clear all errors
        form.find('fieldset .error').remove();
        form.find('fieldset').removeClass('has-error');

        form.find('[required] input[type="text"], [required] input[type="password"], [required] textarea, [required] select').each(function () {
            let textValue = $(this).val(),
                characterLimit = parseInt($(this).data('character-limit')),
                wordLimit = parseInt($(this).data('word-limit')),
                limitError = '';

            if (characterLimit && textValue.split().length > characterLimit) {
                limitError = 'This is more than ' + characterLimit + ' characters';
            } else if (wordLimit && textValue.split(' ').length > wordLimit) {
                limitError = 'This is more than ' + wordLimit + ' words';
            }

            if (!textValue) {
                $(this).closest('fieldset').append('<div class="error">' + (limitError || emptyMessage) + '</div>');
                $(this).closest('fieldset').addClass('has-error');
            } else if ($(this).attr('type') === 'password' && (form.attr('name') === 'signup' || $(this).attr('name') === 'new_password') && textValue.split('').length < 6) {
                $(this).closest('fieldset').append('<div class="error">Password must be at least 6 characters long.</div>');
                $(this).closest('fieldset').addClass('has-error');
            }
        });

        form.find('[required] select#product_used-select').each(function (e) {
            let numOfProducts = 0;
            if (window.productsUsedSelector) {
                let targetSelector = window.productsUsedSelector[0].selectize;
                numOfProducts = targetSelector.items.length;
            }
            if (numOfProducts < 1) {
                $(this).closest('fieldset').append('<div class="error">' + productsUsedEmpty + '</div>');
                $(this).closest('fieldset').addClass('has-error');
            }
        });

        form.find('input[type="url"]').each(function () {
            let urlValue = $(this).val(),
                isRequired = $(this).parents('[required]').size(),
                inputName = $(this).attr('name');

            if (!urlValue && isRequired) {
                $(this).closest('fieldset').append('<div class="error">' + emptyMessage + '</div>');
                $(this).closest('fieldset').addClass('has-error');
            } else if (urlValue) {
                if (!isUrlValid(urlValue)) {
                    $(this).closest('fieldset').append('<div class="error">' + invalidURLMessage + '</div>');
                    $(this).closest('fieldset').addClass('has-error');
                } else if (inputName === 'video' && !isVideoUrlValid(urlValue)) {
                    $(this).closest('fieldset').append('<div class="error">' + invalidVideoURLMessage + '</div>');
                    $(this).closest('fieldset').addClass('has-error');
                } else if (inputName === 'partner_directory_url' && !isHubSpotPartnerUrlValid(urlValue)) {
                    $(this).closest('fieldset').append('<div class="error">' + invalidHubSpotPartnerURLMessage + '</div>');
                    $(this).closest('fieldset').addClass('has-error');
                }
            }
        });

        form.find('[required] input[type="email"]').each(function () {
            let emailValue = $(this).val();
            if (!emailValue) {
                $(this).closest('fieldset').append('<div class="error">' + emptyMessage + '</div>');
                $(this).closest('fieldset').addClass('has-error');
            } else if (!isEmailValid(emailValue)) {
                $(this).closest('fieldset').append('<div class="error">' + invalidEmailMessage + '</div>');
                $(this).closest('fieldset').addClass('has-error');
            }
        });

        form.find('[required] input[type="file"]').each(function () {
            let fileValue = $(this).val(),
                customErrorMsg = $(this).data('error-msg');
            if (!fileValue || customErrorMsg) {
                $(this).closest('fieldset').append('<div class="error">' + (customErrorMsg || fileEmpty) + '</div>');
                $(this).closest('fieldset').addClass('has-error');
            }
        });

        form.find('[required] input[type="checkbox"]').each(function () {
            if (!$(this).prop('checked')) {
                $(this).closest('fieldset').append(`<div class="error">${emptyMessage}</div>`);
                $(this).closest('fieldset').addClass('has-error');
            }
        });

        return !(form.find('fieldset .error').length >= 1);
    }

    submitHubSpotForm(portalId, formId, hubtk, data, legal=null) {
        let hsData = {
            fields: data,
        };
        if (hubtk) {
            hsData.context = {
                hutk: hubtk
            };
        }
        if(legal) {
            hsData.legalConsentOptions = {
                consent: {
                    consentToProcess: true,
                    text: legal.processText,
                    communications: [
                        {
                            value: true,
                            subscriptionTypeId: legal.subscriptionTypeId,
                            text: legal.communicationsText,
                        }
                    ]
                }
            }
        }
        return $.ajax({
            url: 'https://api.hsforms.com/submissions/v3/integration/submit/' + portalId + '/' + formId,
            type: "POST",
            data: JSON.stringify(hsData),
            contentType: 'application/json',
            processData: false,
            dataType: 'json',
        });
    }

    logOut() {
        const self = this;
        $.ajax({
            url: self.getAPIEndpointURL('/auth/logout'),
            type: "POST",
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            }
        }).done(function (data) {
            window.user = null;
            localStorage.removeItem('booyaToken');
            self.trigger(self.events.LOGOUT_SUCCESS);

            if(!self.isEventOverridden(self.events.LOGOUT_SUCCESS)) {
                if(self.config.guestUrl) {
                    location.href = self.config.guestUrl;
                }
            }
        }).fail(function (error) {
            self.trigger(self.events.LOGOUT_FAILED);
        });
    }

    verifyUser() {
        const self = this,
            authToken = localStorage.getItem('booyaToken'),
            code = this.getUrlParameter('code'),
            verificationKey = self.getUrlParameter('verification_key'),
            isVerified = localStorage.getItem('booyaVerified');

        let token = (code && /^btc-.+/.test(code))?code:authToken;

        const showAndClearVerified = () => {
            self.widgets.renderAccountVerifiedBanner();
            localStorage.removeItem('booyaVerified');
        };

        $.ajax({
            url: self.getAPIEndpointURL('/auth/verify'),
            ...(token?{
                type: "POST",
                data: 'token=' + token
            }:{
                type: "GET",
            }),
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            }
        }).done(function (data) {
            if (data.user) {
                window.user = data.user;

                if(data.token) {
                    localStorage.setItem('booyaToken', data.token);
                    if(code) {
                        // Remove code from URL
                        history.replaceState(null, "", location.pathname);

                        if(token === code) {
                            // User just logged in with OAuth
                            self.trigger(self.events.OAUTH_LOGIN_SUCCESS);
                        }
                    }
                }

                self.trigger(self.events.IDENTIFY_SUCCESS, {user: data.user});

                let user = data.user;

                // Prompt user for email verification
                if (user.email && user.not_verified && !self.getUrlParameter('verification_key')) {
                    var resendSelector = '__booya-email--verification-resend';
                    let verificationMessage = 'Your email address (' + user.email + ') is not verified. Please check your inbox for instructions or <a href="#" class="' + resendSelector+ '">click here</a> to resend the verification email.';

                    self.widgets.renderBanner(verificationMessage, self.widgets.banners.DANGER, true);

                    initVerificationEmailSender();

                    function initVerificationEmailSender() {
                        $('.'+resendSelector).off('click').click(function (e) {
                            e.preventDefault();
                            const verificationBannerWrapper = $(this).closest('.__booya-banner');
                            const verificationMessageWrapper = verificationBannerWrapper.find('.__booya-banner-message')
                            verificationMessageWrapper.html('Sending ...');

                            $.ajax({
                                url: self.getAPIEndpointURL("/auth/resend-verification/"),
                                type: "POST",
                                data: 'email=' + user.email + '&next_url=' + (self.getUrlParameter('next') || location.href),
                                dataType: 'json',
                                xhrFields: {
                                    withCredentials: true
                                }
                            }).done(function (data) {
                                verificationMessageWrapper.html(
                                    '🎉 A verification email has been sent to ' + user.email + '. Please check your inbox for instructions.'
                                );
                                verificationBannerWrapper.addClass('__booya-banner-success');
                                verificationBannerWrapper.removeClass('__booya-banner-danger');

                                setTimeout(function () {
                                    verificationBannerWrapper.remove();
                                }, 5000);
                            }).fail(function (error) {
                                verificationMessageWrapper.html(
                                    'Failed to resend verification email to ' + user.email + '. Please <a href="#" class="'+resendSelector+'">try again</a>'
                                );
                                initVerificationEmailSender();
                            });
                        });
                    }
                }

                // Prompt User to complete profile if necessary
                if (!user.first_name || !user.last_name) {
                    self.trigger(self.events.COMPLETE_PROFILE_PROMPT);

                    if(!self.isEventOverridden(self.events.COMPLETE_PROFILE_PROMPT)) {
                        let formSelector = 'form.__booya-profile-form';
                        let form = $(formSelector);
                        if(form.size()) {
                            form.find('input[name="first_name"]').val(user.first_name || '');
                            form.find('input[name="last_name"]').val(user.last_name || '');
                            form.find('input[name="telephone"]').val(user.telephone || '');

                            form.find('.__booya-modal-overlay').show();

                            self.initUI();
                        } else {
                            self.widgets.renderModal(
                                '',
                                self.widgets.renderProfileForm(),
                                {
                                    isOpen: true,
                                },
                            )
                        }
                    }
                }

                /* Identify User in HubSpot */
                if (user.email) {
                    let hubtk = self.getCookie('hubspotutk');
                    let trackingFormId = data.tracking_form_id;
                    window.registrationFormId = data.registration_form_id;

                    if (!data.user.is_tracked && hubtk && self.config.portalID) {
                        // Submit form for user's that aren't being tracked yet
                        self.submitHubSpotForm(self.config.portalID, trackingFormId, hubtk, [
                            {
                                name: 'email',
                                value: user.email,
                            }
                        ]).done(function (data) {
                        }).fail(function (error) {
                        });
                    }
                }
            }

            if(verificationKey) {
                self.verifyAccount();
            } else if(isVerified) {
                showAndClearVerified();
            }
        }).fail(function (error) {
            if(verificationKey) {
                // Attempt to verify with verification_key
                self.verifyAccount();
            } else {
                self.trigger(self.events.IDENTIFY_FAILED);

                if(isVerified) {
                    showAndClearVerified();
                }
            }
        });
    }

    verifyAccount() {
        const self = this;
        const token = this.getUrlParameter('verification_key');

        if (token) {
            $.ajax({
                url: this.getAPIEndpointURL('/auth/verify/token'),
                type: "POST",
                data: 'token=' + token + (window.user?'':`&authenticate=true`),
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                }
            }).done(function (data) {
                // Remove key from URL
                history.replaceState(null, "", location.pathname);

                if(data.token) {
                    localStorage.setItem('booyaToken', data.token);
                } else if(!window.user) {
                    self.trigger(self.events.IDENTIFY_FAILED);
                }

                if(window.user || !data.token) {
                    // Show banner if no redirects are expected
                    self.widgets.renderAccountVerifiedBanner();
                } else {
                    // Otherwise wait for redirect/reload
                    localStorage.setItem('booyaVerified', 'true');
                }

                self.trigger(self.events.VERIFY_ACCOUNT_SUCCESS);

                if(!self.isEventOverridden(self.events.VERIFY_ACCOUNT_SUCCESS) && !window.user && data.token) {
                    // Reload to pick up new session
                    location.reload();
                }
            }).fail(function (error) {
                self.trigger(self.events.VERIFY_ACCOUNT_FAILED);
                if(!window.user) {
                    self.trigger(self.events.IDENTIFY_FAILED);
                }
            });
        }
    }

    initResetPassword() {
        const token = this.getUrlParameter('recovery_key');
        if (token) {
            window.recoveryToken = token;

            let formSelector = 'form.__booya-reset-password-form';
            let form = $(formSelector);
            if(!form.size()) {
                // Redirect to reset form
                if(this.config.resetUrl && location.href !== this.config.resetUrl) {
                    location.href = `${this.config.resetUrl}?recovery_key=${token}`;
                    return ;
                }

                $('body').append(
                    this.widgets.renderModal(
                        '',
                        this.widgets.renderResetForm(),
                        {
                            isOpen: true,
                        },
                    )
                );
                this.initUI();
                form = $(formSelector);
            }

            if(form.size()) {
                form.find('.__booya-modal-overlay').show();
            }
        }
    }

    processHubSpotProfileData(rawData) {
        const PROFILE_FIELD_MAP = {
            first_name: 'firstname',
            last_name: 'lastname',
            telephone: 'phone',
        };

        let data = [];
        $.each(rawData, function (idx, item) {
            if (item.value && !/password|LEGAL_CONSENT/i.test(item.name)) {
                data.push({
                    name: PROFILE_FIELD_MAP[item.name] || item.name,
                    value: item.value,
                });
            }
        });
        return data;
    }

    initUI = () => {
        const self = this;

        /* Modals */
        $('.__booya-modal-overlay .__booya-close').off('click').click(function (e) {
            e.preventDefault();
            $(this).closest('.__booya-modal-overlay').remove();
        });

        /* Auth Widget Section Switches */
        for (let section of ['signin', 'signup', 'recover']) {
            $(`.${this.widgets.selectors.authWidgetSectionSwitch}-${section}`).off('click').click(function (e) {
                e.preventDefault();
                self.widgets.openAuthWidget(section);
            });
        }

        /* Banners */
        $('.__booya-banner-close').off('click').click(function (e) {
            $(this).closest('.__booya-banner').remove();
        });

        /* Auth Forms */
        $('form.__booya-verify-form').find('button[type="submit"]').off('click').click(function (e) {
            e.preventDefault();

            let form = $(this).parents('form');

            let submitButton = $(this),
                formSuccess = form.find('.form-success'),
                formError = form.find('.form-error'),
                formValidationError = form.find('.form-validation-error'),
                originalSubmitText = submitButton.html();

            formSuccess.hide();
            formError.hide();
            formValidationError.hide();
            formError.html(formError.attr('data-error'));

            let validationCheck = self.validateForm(form);
            if (validationCheck) {
                let data = form.serialize();

                submitButton.attr('disabled', 'disabled');
                submitButton.html('Verifying ...');

                var email = form.find('input[name="email"]').val();

                $.ajax({
                    url: self.getAPIEndpointURL("/auth/verify/email"),
                    type: "POST",
                    data: data,
                    dataType: 'json',
                    xhrFields: {
                        withCredentials: true
                    }
                }).done(function (data) {
                    form.get(0).reset();

                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    //formSuccess.show();
                    //formError.hide();
                    //formValidationError.hide();

                    self.trigger(self.events.ACCOUNT_EXISTS_SUCCESS, {email});
                }).fail(function (error) {
                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    //formError.show();
                    //formSuccess.hide();
                    //formValidationError.hide();

                    self.trigger(self.events.ACCOUNT_EXISTS_FAILED, {email});
                });
            }
        });

        $('form.__booya-signin-form').find('button[type="submit"]').off('click').click(function (e) {
            e.preventDefault();

            let form = $(this).parents('form');

            let submitButton = $(this),
                formSuccess = form.find('.form-success'),
                formError = form.find('.form-error'),
                formValidationError = form.find('.form-validation-error'),
                originalSubmitText = submitButton.html();

            formSuccess.hide();
            formError.hide();
            formValidationError.hide();
            formError.html(formError.attr('data-error'));

            let validationCheck = self.validateForm(form);
            if (validationCheck) {
                let data = form.serialize();

                submitButton.attr('disabled', 'disabled');
                submitButton.html('Verifying ...');

                $.ajax({
                    url: self.getAPIEndpointURL("/auth/signin/"),
                    type: "POST",
                    data: data,
                    dataType: 'json',
                    xhrFields: {
                        withCredentials: true
                    }
                }).done(function (data) {
                    form.get(0).reset();

                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    formSuccess.show();
                    formError.hide();
                    formValidationError.hide();

                    form.parents('.__booya-modal-overlay').hide();

                    if(data && data.token) {
                        localStorage.setItem('booyaToken', data.token);
                    }

                    self.trigger(self.events.LOGIN_SUCCESS);

                    if(!self.isEventOverridden(self.events.LOGIN_SUCCESS)) {
                        const nextUrl = self.getUrlParameter('next');
                        if(nextUrl){
                            location.href = nextUrl;
                        } else if(self.config.memberUrl) {
                            location.href = self.config.memberUrl;
                        } else {
                            location.reload();
                        }
                    }
                }).fail(function (error) {
                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);

                    if (error && error.responseText) {
                        let data = $.parseJSON(error.responseText);
                        if (data && data.reason === 'verify') {
                            formError.html("❌ Please verify your email before logging in.<br/> Check your email inbox for instructions.");
                        }
                    }

                    formError.show();
                    formSuccess.hide();
                    formValidationError.hide();

                    self.trigger(self.events.LOGIN_FAILED);
                });
            }
        });

        $('form.__booya-signup-form').find('button[type="submit"]').off('click').click(function (e) {
            e.preventDefault();

            let form = $(this).parents('form');

            let submitButton = $(this),
                formSuccess = form.find('.form-success'),
                formError = form.find('.form-error'),
                formValidationError = form.find('.form-validation-error'),
                originalSubmitText = submitButton.html();

            formSuccess.hide();
            formError.hide();
            formValidationError.hide();
            formError.html(formError.attr('data-error'));

            let validationCheck = self.validateForm(form);
            if (validationCheck) {
                let data = form.serialize();

                const nextUrl = self.getUrlParameter('next');
                if(nextUrl){
                    data = `${data}&next_url=${nextUrl}`;
                }

                submitButton.attr('disabled', 'disabled');
                submitButton.html('Saving ...');

                $.ajax({
                    url: self.getAPIEndpointURL("/auth/register/"),
                    type: "POST",
                    data: data,
                    dataType: 'json',
                    xhrFields: {
                        withCredentials: true
                    }
                }).done(function (data) {
                    // Send user details to HubSpot
                    let hsData = self.processHubSpotProfileData(form.serializeArray());

                    let hubtk = self.getCookie('hubspotutk');
                    if (data) {
                        window.registrationFormId = data && data.registration_form_id || null;

                        let registrationFormId = window.registrationFormId;
                        let email = data.user && data.user.email || '';

                        if (self.config.portalID && email && registrationFormId && hubtk) {
                            let legalProcessing = form.find('input[name="LEGAL_CONSENT.processing"]');
                            let legalCommunications = form.find('input[name="LEGAL_CONSENT.subscription_type"]');

                            let legalBasis = null;
                            if(legalCommunications && legalCommunications.is(':checked') && legalProcessing && legalProcessing.is(':checked')) {
                                legalBasis = {
                                    communications: true,
                                    subscriptionTypeId: parseInt(legalCommunications.val() || ''),
                                    communicationsText: legalCommunications.attr('data-label'),
                                    process: true,
                                    processText: legalProcessing.attr('data-label'),
                                };
                            }

                            self.submitHubSpotForm(self.config.portalID, registrationFormId, hubtk, hsData, legalBasis).done(function (data) {

                            }).fail(function (error, data) {
                            });
                        }
                    }

                    // Update Form
                    form.get(0).reset();

                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    formSuccess.show();
                    formError.hide();
                    formValidationError.hide();

                    self.trigger(self.events.REGISTER_SUCCESS);
                }).fail(function (error) {
                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);

                    if (error && error.responseText) {
                        let data = $.parseJSON(error.responseText);
                        if (data && data.reason === 'exists') {
                            formError.html("❌ An account with this email already exists.");
                        }
                    }

                    formError.show();
                    formSuccess.hide();
                    formValidationError.hide();

                    self.trigger(self.events.REGISTER_FAILED);
                });
            } else {
                submitButton.html(originalSubmitText);
                submitButton.prop('disabled', false);
                formValidationError.show();
                formError.hide();
                formSuccess.hide();
            }
        });

        $('form.__booya-profile-form').find('button[type="submit"]').off('click').click(function (e) {
            e.preventDefault();

            let form = $(this).parents('form');

            let submitButton = $(this),
                formSuccess = form.find('.form-success'),
                formError = form.find('.form-error'),
                formValidationError = form.find('.form-validation-error'),
                originalSubmitText = submitButton.html();

            formSuccess.hide();
            formError.hide();
            formValidationError.hide();

            let validationCheck = self.validateForm(form);

            let registrationFormId = window.registrationFormId;
            let email = window.user && window.user.email || '';

            if (validationCheck && self.config.portalID && email && registrationFormId) {
                let rawData = form.serializeArray();
                rawData.push({
                    name: 'email',
                    value: email,
                });

                let data = self.processHubSpotProfileData(rawData);

                submitButton.attr('disabled', 'disabled');
                submitButton.html('Saving ...');

                self.submitHubSpotForm(self.config.portalID, registrationFormId, self.getCookie('hubspotutk') || null, data).done(function (data) {
                    //form.get(0).reset();

                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    formSuccess.show();
                    formError.hide();
                    formValidationError.hide();

                    self.trigger(self.events.PROFILE_UPDATE_SUCCESS);
                }).fail(function (error) {
                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    formError.show();
                    formSuccess.hide();
                    formValidationError.hide();

                    self.trigger(self.events.PROFILE_UPDATE_FAILED);
                });
            } else {
                submitButton.html(originalSubmitText);
                submitButton.prop('disabled', false);
                formValidationError.show();
                formError.hide();
                formSuccess.hide();
            }
        });

        $('form.__booya-recover-account-form').find('button[type="submit"]').off('click').click(function (e) {
            e.preventDefault();

            let form = $(this).parents('form');

            let submitButton = $(this),
                formSuccess = form.find('.form-success'),
                formError = form.find('.form-error'),
                formValidationError = form.find('.form-validation-error'),
                originalSubmitText = submitButton.html();

            formSuccess.hide();
            formError.hide();
            formValidationError.hide();

            let validationCheck = self.validateForm(form);
            if (validationCheck) {
                let data = form.serialize();

                submitButton.attr('disabled', 'disabled');
                submitButton.html('Verifying ...');

                $.ajax({
                    url: self.getAPIEndpointURL("/auth/recover/"),
                    type: "POST",
                    data: data,
                    dataType: 'json',
                    xhrFields: {
                        withCredentials: true
                    }
                }).done(function (data) {
                    form.get(0).reset();

                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    formSuccess.show();
                    formError.hide();
                    formValidationError.hide();

                    self.trigger(self.events.RECOVER_SUCCESS);
                }).fail(function (error, data) {
                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    formError.show();
                    formSuccess.hide();
                    formValidationError.hide();

                    self.trigger(self.events.RECOVER_FAILED);
                });
            }
        });

        $('form.__booya-reset-password-form').find('button[type="submit"]').off('click').click(function (e) {
            e.preventDefault();

            let form = $(this).parents('form');

            let submitButton = $(this),
                formSuccess = form.find('.form-success'),
                formError = form.find('.form-error'),
                formValidationError = form.find('.form-validation-error'),
                originalSubmitText = submitButton.html();

            formSuccess.hide();
            formError.hide();
            formValidationError.hide();
            formError.html(formError.attr('data-error'));

            let validationCheck = self.validateForm(form);
            if (validationCheck) {
                let data = form.serialize();

                submitButton.attr('disabled', 'disabled');
                submitButton.html('Saving ...');

                $.ajax({
                    url: self.getAPIEndpointURL("/auth/change-password/"),
                    type: "POST",
                    data: 'token=' + encodeURIComponent(window.recoveryToken) + '&' + data,
                    dataType: 'json',
                    xhrFields: {
                        withCredentials: true
                    }
                }).done(function (data) {
                    form.get(0).reset();
                    window.recoveryToken = null;

                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);
                    formSuccess.show();
                    formError.hide();
                    formValidationError.hide();

                    history.replaceState(null, "", location.pathname);

                    self.trigger(self.events.RESET_SUCCESS);
                }).fail(function (error) {
                    submitButton.html(originalSubmitText);
                    submitButton.prop('disabled', false);

                    if (error && error.responseText) {
                        let data = $.parseJSON(error.responseText);
                        if (data && ['invalid_token', 'no_user'].indexOf(data.reason) > -1) {
                            formError.html("❌ This link is either invalid or has expired.<br/>Please generate a new recovery link <a href='#' class='__booya-modal-switch' data-modal='__booya-modal-recover-account'>here<a>");
                        }
                    }

                    formError.show();
                    formSuccess.hide();
                    formValidationError.hide();

                    self.trigger(self.events.RESET_FAILED);
                });
            }
        });
    };

    init(config) {
        this.setConfig(config);

        if(this.config && (this.config.mode === 'staging' && !this.config.isTest)) {
            // Don't initialize if mode is staging and we're not a test url
            console.log('Add ?booya-test=true to run booya in test mode');
            return ;
        }

        /* Init auth components */
        if(!this.isInitialized) {
            this.verifyUser();
            this.initResetPassword();

            this.isInitialized = true;
        }

        this.initUI();
    }

    on(event, callback) {
        if(typeof callback === 'function') {
            $(document).on(event, callback);

            if(!this.state.events || !Array.isArray(this.state.events)) {
                this.state.events = []
            }

            if(!this.state.events.includes(event)) {
                this.state.events.push(event);
            }
        }
    }

    ready(callback) {
        if(typeof callback === 'function') {
            if(this.isReady) {
                // trigger immediately if app is ready
                callback();
            }
            this.on(this.events.READY, callback);
        }
    }
}

window.booya = new Booya();